import React from 'react';
import Layout from '../../components/App/Layout';
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {Link} from 'gatsby';
import { graphql } from 'gatsby'
const img = '/img/desktop/BlueMail-Mac-Application.png';


const macPage = ({data}) => (

    <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO 
            postTitle="Mac | BlueMail App"
            postDescription="The Most Powerful Email App is Now Here on Mac"
            postURL="desktop/mac" 
        />
        <div className="container pt-120 pb-100">
            <div className='row'>
                <div className='col-12 center-text'>
                    <h2 style={{marginBottom: '0.1em'}}>The Most Powerful Email App is Now Here on Mac</h2>
                    <a href='https://apps.apple.com/us/app/bluemail/id1458754578?mt=12'>
                        <img src={img} style={{maxHeight: '500px'}} className='desktop-img' alt='BlueMail for Mac OS' />
                    </a>
                    <div>
                        <a href='https://apps.apple.com/us/app/bluemail/id1458754578?mt=12' target="_blank" rel="noreferrer" className='default-btn'>
                        Download for Mac
                        </a>
                    </div>
                    <div className="mt-30">
                        <p>Not on Mac?<br/>Download for <Link to='/desktop/windows/'><b style={{color:'#1F6BF1'}}>Windows</b></Link> or <Link to='/desktop/linux/'><b style={{color:'#1F6BF1'}}>Linux</b></Link></p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
)

export default macPage

export const query = graphql`
query MacPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`